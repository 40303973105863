<template>
    <Section :class="slice.slice_label" width="lg">
        <div v-if="isShowingAllCards" class="sorting">
            <button
                class="btn btn-sm"
                :class="activeSort === 'recommended' ? 'btn-green' : 'btn-green-border'"
                @click="activeSort = 'recommended'"
            >
                {{ $translate('sort_recommended', 'Rekommenderade först') }}
            </button>
            <button
                class="btn btn-sm"
                :class="activeSort === 'alphabetical' ? 'btn-green' : 'btn-green-border'"
                @click="activeSort = 'alphabetical'"
            >
                {{ $translate('sort_alphabetical', 'Alfabetiskt') }}
            </button>
        </div>

        <section-header
            id="cards-header"
            :title="slice.primary.title"
            :teaser="slice.primary.teaser"
        />

        <ul class="card-list">
            <card
                v-for="card in cardsToShow"
                :key="card.id"
                :card="card"
                :category-data-key="categoryDataKey"
            />
            <card
                v-for="card in additionalCardsToShow"
                v-show="additionalCardsVisible"
                :key="card.id"
                :card="card"
                :category-data-key="categoryDataKey"
            />
        </ul>
        <Button 
            v-if="showViewMoreButton"
            :text="$translate('show_more', 'Visa mer')"
            class="view-more"
            background="green-border"
            size="sm"
            @click="additionalCardsVisible = true"
        />
        <trust-us />
    </Section>
</template>

<script>
import Card from './list-item/ListItemCard.vue';
import TrustUs from '@/components/TrustUs.vue';
import { orderBy, partition } from 'lodash';
export default {
    components: { Card, TrustUs },
    props: {
        slice: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    data() {
        return {
            activeSort: 'recommended',
            additionalCardsVisible: false,
            viewMoreButtonEnabled: this.slice.primary.show_view_more,
        };
    },
    computed: {
        selectedCards() {
            let cards = [];
            if (this.slice.primary.list_preset.id) {
                cards = this.$store.state.lists[this.slice.primary.list_preset.id].data.cards;
                // return map(list, 'card');
            }
            else {
                cards = this.slice.items;
            }

            cards = cards
                .map(iterator => iterator.card)
                .filter(card => card.id && card.type !== 'broken_type');

            return cards;
        },
        allCards() {
            return Object.values(this.$store.state.cards);
        },
        cards() {
            if (!this.isShowingAllCards) {
                return this.selectedCards;
            }

            return this.allCards;
        },
        isShowingAllCards() {
            return !this.selectedCards.length;
        },
        cardsToShow() {
            if (this.isShowingAllCards) {
                return this.sortedCards;
            }

            return this.cards
                .map(card => this.allCards.find(entry => entry.id === card.id))
                .filter(card => !card.data.lists_disabled);
        },
        additionalCardsToShow() {
            if (this.isShowingAllCards) {
                return null;
            }
            const cardsToShowIDs = this.cardsToShow.map(card => card.id);
            const additionalCards = this.allCards.filter(card => !cardsToShowIDs.includes(card.id));

            return this.sortOnRecommended(additionalCards);
        },
        sortedCards() {
            if (this.activeSort === 'recommended') {
                return this.sortOnRecommended(this.cards);
            }

            return orderBy(this.cards, 'data.title', 'asc');
        },
        categoryDataKey() {
            return this.slice.primary.category_data_key;
        },
        showViewMoreButton() {
            return !this.additionalCardsVisible && this.viewMoreButtonEnabled;
        },
    },
    methods: {
        sortOnRecommended(cards) {
            const [partnerCards, otherCards] = partition(
                cards,
                card => 
                    card.data.application_type !== 'no_partnership' && 
                    card.data.application_type !== null
            );
            return [...this.$getOrderedList(partnerCards), ...this.$getOrderedList(otherCards)];
        },
    },
};
</script>

<style lang="scss" scoped>
section {
    .card-list {
        width: 100%;
        @include grid(1, 30px);
        
        .hidden {
            display: none;
        }
    }

    .sorting {
        display: flex;
        flex-wrap: wrap;
        gap: 1rem;
        row-gap: 0.5rem;
        margin-bottom: 1rem;
        @include font-size(16px);
        font-weight: bold;
        font-family: $fontHeader;
        button {
            border-width: 1px;
        }
    }

    .view-more{
        margin-top: 1rem;
        justify-self: center;
    }
}
</style>
